<template>
<div class="privacy">

    <v-app-bar app flat color="primary" dark prominent>

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="goback" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-h5 font-weight-light" style="letter-spacing:1px !important">Child Safety & CSAE Policy</v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-slot:img>
            <v-icon size="180" class="pl-8 pt-4 float-left" color="#fff" style="opacity:.15">mdi-file-document</v-icon>
        </template>

    </v-app-bar>

    <v-row no-gutters>
        <v-col class="pa-4">

            <div class="pb-4">Updated at 2025-02-28 </div>

            <p>
                At PetMeetly, we are committed to maintaining a safe and secure platform for all users, including minors. We have a zero-tolerance policy against any form of Child Sexual Abuse and Exploitation (CSAE) and strictly enforce Google Play’s child safety policies.
            </p>


            <h3 class="py-4">Prohibited Content & Activities </h3>

            We strictly prohibit: <br /><br />
            <ul>
                <li>The sharing of explicit content involving minors.</li>
                <li>Any grooming, predatory behavior, or inappropriate interactions with minors.</li>
                <li>Any content that promotes, normalizes, or facilitates child exploitation.</li>
                <li>The use of PetMeetly for harmful, misleading, or exploitative activities targeting minors.</li>
            </ul>

            <h3 class="py-4">Safety Measures & Enforcement </h3>
            <ul>
                <li><strong>Automated & Manual Moderation:</strong> We use AI tools and human review to detect and remove inappropriate content.</li>
                <li><strong>User Reporting System:</strong> Any user can report suspicious behavior using our in-app reporting feature or email [support@petmeetly.com].</li>
                <li><strong>Strict Account Actions:</strong> Any violation of this policy will result in immediate account suspension and reporting to relevant legal authorities.</li>
                <li><strong>Compliance with Global Laws:</strong> We adhere to child protection laws, including COPPA (Children’s Online Privacy Protection Act) and GDPR-K (General Data Protection Regulation for Kids).</li>
            </ul>
            <br />
            <p>
                We also collect information from mobile devices for a better user experience, although these features are completely optional:
            </p>

            <h3 class="py-4">How to Report CSAE Content? </h3>
            <p>If you encounter any form of CSAE content or suspicious behavior, please report it immediately: </p>
            <ul>
                <li><strong>Email:</strong> [support@petmeetly.com]</li>
                <li><strong>In-App Report Feature:</strong> Click the Report button on any user/pet profile</li>
                <li><strong>Legal Authorities:</strong> We cooperate with law enforcement to report and prevent abuse.</li>
            </ul>

            
            <br/>
            <p>PetMeetly is dedicated to creating a safe and responsible pet adoption and breeding community. We continuously update our safety measures to protect children and prevent abuse.</p>
            <p>For more details on our privacy practices, visit our <router-link to="/privacy">Privacy Policy</router-link>.</p>

        </v-col>
    </v-row>

</div>
</template>

<script>
export default {
    name: 'Csp',
    methods: {
        goback()
        {
            this.$router.go(-1);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Back Button Clicked");
            }
        }
    }
}

</script>
